import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import styled from "styled-components";
import LoadMorePostGrid from "../components/comp-layouts/LoadMorePostGrid";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";

const getData = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "news" } } }
    ) {
      edges {
        node {
          fields {
            slug
            collection
          }
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            description
            alt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
        }
      }
    }
  }
`;

const News = () => {
  const data = useStaticQuery(getData);
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO
        title="News"
        description="Read updates about Adrift Lab's research, events, and conferences attended by our members."
      />
      <Container mt="60px" mb="20px">
        <Owl>
          <h1>Adrift Lab News</h1>
          <p>
            All of the latest updates about our research, events and conferences
            our members attend, and other articles of interest about Adrift Lab.
          </p>
        </Owl>
      </Container>
      <Container width="1200px" mb="50px">
        <div style={{ height: 40 }} />
        <LoadMorePostGrid posts={posts} />
      </Container>
    </Layout>
  );
};

export default News;
